/* Flight Search Results Styling */
.flight-results-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Results Header */
.results-header {
    margin-bottom: 24px;
}

.results-header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.search-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    background-color: #f6f8fa;
    padding: 12px 15px;
    border-radius: 8px;
    font-size: 14px;
    color: #555;
}

.date-display {
    display: flex;
    align-items: center;
}

.flight-count {
    background: #e6f4ff;
    padding: 4px 10px;
    border-radius: 20px;
    font-weight: 500;
    color: #0072c6;
    margin-left: auto;
}

.icon-spacing {
    margin-right: 6px;
}

/* Loading State */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0072c6;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Flight Cards Grid */
.flights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 20px;
}

/* Flight Card */
.flight-card {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #eaeaea;
}

.flight-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
}

/* Flight Card Header */
.flight-card-header {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    background: #f8f9fa;
    border-bottom: 1px solid #eaeaea;
}

.airline-logo {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.airline-name {
    font-weight: 600;
    margin-left: 10px;
    color: #333;
}

.flight-number {
    color: #777;
    font-size: 13px;
    margin-left: auto;
}

/* Flight Route */
.flight-route {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
}

.departure, .arrival {
    text-align: center;
    flex: 1;
}

.time {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.location {
    color: #666;
    font-size: 13px;
    margin-top: 4px;
}

.flight-path {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    flex: 2;
}

.route-line {
    height: 2px;
    background-color: #ddd;
    width: 100%;
    position: relative;
}

.plane-icon {
    position: absolute;
    top: -10px;
    color: #0072c6;
    font-size: 16px;
    background: white;
    padding: 2px;
}

.duration {
    font-size: 12px;
    color: #777;
    margin-top: 10px;
    text-align: center;
}

/* Flight Details */
.flight-details {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-top: 1px solid #eaeaea;
    background: #fafafa;
}

.detail-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
}

.detail-icon {
    margin-right: 5px;
    color: #777;
}

.price {
    font-weight: bold;
    color: #e74c3c;
}

/* Book Button */
.book-button {
    width: 100%;
    padding: 12px;
    background-color: #0072c6;
    color: white;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.book-button:hover {
    background-color: #005ea6;
}

/* No Results */
.no-results {
    text-align: center;
    padding: 40px 0;
    background: #f9f9f9;
    border-radius: 8px;
}

.no-results-icon {
    font-size: 40px;
    margin-bottom: 15px;
}

.no-results h3 {
    margin-bottom: 10px;
    color: #555;
}

.no-results p {
    color: #777;
}

/* Modal Styling Fix */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff !important;         /* force solid white */
    color: #000;                               /* ensure readable text */
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
    animation: fadeInModal 0.35s ease;
    opacity: 1 !important;                     /* force full visibility */
    backdrop-filter: none !important;          /* remove any internal blur */
}


@keyframes fadeInModal {
    0% { opacity: 0; transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #f2f2f2;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s, color 0.2s;
}

.modal-close:hover {
    background: #e0e0e0;
    color: #000;
}

/* Passenger Selection */
.passenger-selection h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.selected-flight-summary {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.flight-summary-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.summary-airline-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.flight-summary-route {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
}

.summary-time {
    font-weight: 600;
}

.summary-location {
    font-size: 13px;
    color: #666;
}

.summary-arrow {
    color: #999;
}

.flight-summary-date {
    display: flex;
    align-items: center;
    color: #555;
    font-size: 13px;
    margin-top: 10px;
}

.summary-icon {
    margin-right: 5px;
}

.passenger-total {
    border-radius: 8px;
    background: #e6f4ff;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.total-count {
    color: #0072c6;
}

.total-price {
    color: #e74c3c;
}

.rupee-icon {
    font-size: 12px;
    margin-right: 2px;
}

/* Passenger Counter */
.passenger-counters {
    margin-bottom: 20px;
}

.passenger-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.passenger-type {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.passenger-icon {
    margin-right: 10px;
    color: #0072c6;
}

.counter-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.counter-btn {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.counter-btn:hover:not(.disabled) {
    background: #f2f2f2;
    border-color: #bbb;
}

.counter-btn.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.counter-value {
    width: 30px;
    text-align: center;
    font-weight: 500;
}

/* Continue Button */
.continue-button {
    width: 100%;
    padding: 12px;
    background-color: #0072c6;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

.continue-button:hover {
    background-color: #005ea6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .flights-grid {
        grid-template-columns: 1fr;
    }

    .search-summary {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .flight-count {
        margin-left: 0;
    }

    .passenger-total {
        flex-direction: column;
    }
}
