/* General Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff; /* Blue Theme */
    padding: 15px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Logo Styling */
.logo a {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

/* Navigation Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    padding: 8px 12px;
    transition: all 0.3s ease-in-out;
}

.nav-links a:hover {
    background-color: white;
    color: #3a83ac;
    border-radius: 5px;
}

/* Mobile Menu Icon */
.menu-icon {
    display: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

/* Responsive Navbar */
@media screen and (max-width: 768px) {
    .menu-icon {
        display: block;
    }

    .nav-links {
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #007bff;
        width: 100%;
        text-align: center;
        flex-direction: column;
        padding: 10px 0;
        display: none;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-links li {
        display: block;
        padding: 10px;
    }
}
