/* AdminLogin.css */

.admin-login-page {
    background-size: cover;
    background-position: center;
    padding: 30px 0;
}

.login-form-container {
    animation: fadeIn 0.6s ease-in-out;
}

.admin-logo {
    width: 150px;
    height: auto;
    margin-bottom: 15px;
}

.login-card {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2) !important;
}

.input-group-text {
    background-color: #f8f9fa;
    color: #6c757d;
}

.form-control:focus {
    border-color: #C03952;
    box-shadow: 0 0 0 0.25rem rgba(192, 57, 82, 0.25);
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .login-card {
        padding: 15px !important;
    }
    
    .admin-logo {
        width: 120px;
    }
}