/* TicketBooking1.css */

/* Reset and base styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .booking-container {
    width: 100%;
    overflow-x: hidden;
  }
  
  /* Hero section styles */
  .booking-hero {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .gradient-overlay {
    background: linear-gradient(90deg, rgba(226,182,154,0.8) 0%, rgba(192,57,82,0.8) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  /* Logo styles */
  .logo-container {
    z-index: 2;
  }
  
  .logo-container img {
    width: 150px;
    height: auto;
  }
  
  /* Admin button styles */
  .admin-login-btn {
    z-index: 2;
    border: 1px solid white !important;
    color: white !important;
    padding: 5px 15px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
  }
  
  .admin-login-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Hero content styles */
  .hero-content {
    position: relative;
    z-index: 2;
    padding: 0 50px;
  }
  
  .hero-content h1 {
    font-size: 4.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1.2;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .hero-content p {
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  /* Social media icons */
  .social-icons {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }
  
  .social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Search form styles */
  .search-form-container {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 1200px;
    z-index: 2;
  }
  
  .search-box {
    background-color: rgba(17, 24, 33, 0.95) !important;
    border-radius: 8px !important;
    padding: 25px !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .form-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .input-group-text {
    background-color: white;
    border-right: none;
    color: #666;
  }
  
  .form-select, .form-control {
    height: 45px;
    font-size: 0.95rem;
  }
  
  .form-select {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .search-button {
    background-color: #C03952 !important;
    color: white !important;
    height: 45px;
    font-weight: 500;
    font-size: 1rem;
    border: none;
    transition: all 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #a62e42 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Services section */
  .services {
    background-color: #C03952 !important;
    padding: 25px 0;
  }
  
  .services img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .services div {
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
  
  /* Footer styles */
  footer {
    background-color: #1A1D21 !important;
    color: #fff;
    padding: 15px 0;
    font-size: 0.85rem;
  }
  
  /* FlightSearchResults styles */
  .search-results {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-content h1 {
      font-size: 3.5rem;
    }
    
    .hero-content p {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .hero-content {
      text-align: center;
      padding: 0 20px;
    }
    
    .hero-content h1 {
      font-size: 2.8rem;
    }
    
    .hero-content p {
      font-size: 1.2rem;
    }
    
    .search-form-container {
      bottom: 10%;
    }
    
    .search-box {
      padding: 15px !important;
    }
    
    .services .d-flex {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
    
    .social-icons {
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    .hero-content h1 {
      font-size: 2rem;
    }
    
    .search-form-container {
      bottom: 5%;
    }
  }
  
  /* Additional styling for AirportSelect component compatibility */
  .select__control {
    min-height: 45px !important;
    border-left: none !important;
  }
  
  .select__placeholder {
    color: #6c757d !important;
  }
  
  .select__indicator-separator {
    display: none;
  }
  
  /* Icon styling for proper alignment */
  .form-control::placeholder,
  .select__placeholder {
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  /* Button styling */
  .btn {
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  /* Calendar icon styling */
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  
  /* Fix button hover states */
  .btn-primary {
    background-color: #1877f2 !important;
    border-color: #1877f2 !important;
  }
  
  .btn-danger {
    background-color: #C03952 !important;
    border-color: #C03952 !important;
  }
  
  .btn-info {
    background-color: #1DA1F2 !important;
    border-color: #1DA1F2 !important;
    color: white !important;
  }
  /* Add these styles to your TicketBooking1.css file */

.services {
    background-color: #C03952 !important;
    padding: 20px 0;
  }
  
  .service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .service-icon-container {
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  
  .service-icon-container svg {
    color: #C03952;
  }
  
  .service-item div:last-child {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
  }
  
  @media (max-width: 768px) {
    .services .d-flex {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }

  /* Add these styles to your TicketBooking1.css file */

/* Admin login button styles */
.admin-login-btn {
    z-index: 2;
    border: 1px solid white !important;
    color: white !important;
    padding: 5px 15px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
  }
  
  .admin-login-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Mobile admin login button */
  .admin-login-mobile-btn {
    z-index: 2;
    border: 1px solid white !important;
    color: white !important;
    padding: 6px 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .admin-login-mobile-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  /* Responsive adjustments for the logo and layout */
  @media (max-width: 768px) {
    .logo-container img {
      width: 120px;
    }
    
    .hero-content {
      text-align: center;
      padding: 0 20px;
      margin-top: 25% !important;
    }
    
    .hero-content h1 {
      font-size: 2.5rem;
    }
    
    .hero-content p {
      font-size: 1.2rem;
    }
    
    .search-form-container {
      bottom: 5%;
      width: 95%;
    }
    
    .search-box {
      padding: 15px !important;
    }
    
    .form-label {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 576px) {
    .hero-content h1 {
      font-size: 2rem;
    }
    
    .service-item {
      margin: 0 5px;
    }
    
    .service-icon-container {
      width: 40px;
      height: 40px;
    }
    
    .service-icon-container svg {
      width: 20px;
      height: 20px;
    }
    
    .service-item div:last-child {
      font-size: 0.7rem;
    }
  }
  /* Service Icons Section Styles */
.services {
    background-color: #f8f9fa;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .service-strip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 900px;
    margin: 0 auto;
    padding: 8px 0;
  }
  
  .service-item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 0.85rem;
    font-weight: 500;
    color: #555;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
  
  .service-item:hover {
    color: #007bff;
    transform: translateY(-2px);
  }
  
  .service-icon {
    margin-right: 8px;
    color: #007bff;
  }
  
  .service-divider {
    height: 20px;
    width: 1px;
    background-color: #ddd;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .service-strip {
      overflow-x: auto;
      justify-content: flex-start;
      padding: 8px 10px;
    }
    
    .service-item {
      padding: 0 10px;
      font-size: 0.75rem;
    }
    
    .service-icon {
      margin-right: 5px;
    }
  }