.responsive-col {
    width: 100%;
    max-width: 100%;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
}

@media (min-width: 1200px) { 
    .responsive-col {
        width: 50%; /* Set to 50% on screens larger than 1200px */
    }
}
input.form-control {
    font-size: 0.9rem;
  }
  
  label {
    font-weight: 500;
  }
  
  .ticket-list table th,
  .ticket-list table td {
    font-size: 0.85rem;
    vertical-align: middle;
  }
  
  .ticket-list h5 {
    font-weight: 600;
    color: #c03952;
  }
  